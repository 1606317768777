import React from 'react'
import { Link } from 'gatsby'
import { StaticImage } from 'gatsby-plugin-image'

const Header = ({ siteTitle }) => (
  <div
    style={{
      position: 'fixed',
      backgroundColor: '#1D1C1A',
      padding: '1rem',
      textAlign: 'left',
      width: '100%',
      zIndex: '2',
    }}
  >
    <Link
      to="/"
      style={{
        color: 'white',
        textDecoration: 'none',
      }}
    >
      <StaticImage src="../images/logo.svg" alt="Dream Mill" width={200} />
    </Link>
    <nav>
      <Link to="/about">
        {' '}
        <StaticImage src="../images/about.svg" alt="About" className="button" />
      </Link>
      <Link to="/contact">
        <StaticImage
          src="../images/contact.svg"
          alt="Contact"
          className="button button-contact"
        />
      </Link>
    </nav>
  </div>
)

export default Header
